import TYPES from './types';

/**
 * Auth Reducer
 *
 * @param {object} state - state
 * @param {object} action - action type
 * @param {string} action.type - action type
 * @param {object} action.payload - action payload
 * @returns {object} - reducer object
 */
const reducer = (state, { type, payload }) => {
  switch (type) {
    case TYPES.INIT:
      return {
        ...state,
        authState: {
          ...state.authState,
          isInitiated: true,
        },
      };

    case TYPES.SET_AUTH:
      return {
        ...state,
        authState: {
          ...state.authState,
          auth: payload,
        },
      };

    default:
      throw new Error();
  }
};

export default reducer;
