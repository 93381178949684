import axios from 'axios';
import AsyncStorage from '@react-native-async-storage/async-storage';

import { encryptKey } from '../encryptKey';
import { getStorage } from '../../context/storage';
import { authActions } from '../../context/Auth';
import storageKeys from '../../context/storageKeys';
import Service from '../../middleware/services';

const refreshTokenUrl = {
  url: '',
};

/**
 * Sets the URL for refreshing the access token.
 *
 * @param {string} url - The URL to set for refreshing the access token.
 */
export const setRefreshTokenUrl = (url) => {
  refreshTokenUrl.url = url;
};

/**
 * Refresh Access Token
 *
 * @param {object} originalRequest - original request config
 */
const refreshAccessToken = async (originalRequest) => {
  const refreshToken = await getStorage(storageKeys.auth);
  const acceptDigi = await AsyncStorage.getItem('arrakis');

  Service.setDigi(encryptKey(acceptDigi));

  const data = {
    refreshToken: refreshToken.rt,
    // TODO: what should be the redirect url
    redirectUri: 'https://jwt.ms',
  };

  const headers = {
    headers: {
      ...originalRequest.headers,
      'accept-digi': encryptKey(acceptDigi),
    },
  };

  const response = await axios.post(refreshTokenUrl.url, data, headers);

  if (response) {
    authActions.setAuth({
      at: response.data.data.at,
      rt: response.data.data.rt,
    });
  }
};

export default refreshAccessToken;
