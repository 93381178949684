import axios from 'axios';

import storageKeys from '../../context/storageKeys';
import { getStorage } from '../../context/storage';
import { getApiKey, apiKeyHandler } from '../../libs/getApiKey';
import refreshAccessToken from '../../libs/refreshAccessToken';
import {
  removeRequestFromPool,
  requestPollHandler,
} from '../../libs/requestMap';
import { selectPlatform, isEmpty } from '../../helpers/CommonHelper';
import NavigationRoutes from '../../navigation/NavigationRoutes';
import { navigate } from './navigationForService';

/**
 * Initial Header Object
 * Service will start this header configurations
 */
const initialHeader = {
  'accept-digi': '',
  'x-client-name': selectPlatform,
  'accept-language': 'EN',
  'x-application': 'tod2',
  Authorization: '',
  'Cache-Control': 'public, max-age=31536000',
  'x-region': 'QA_5264',
};

/**
 * Service Class
 */
class Service {
  /**
   * Constructor
   */
  constructor() {
    this.mode = '';
    this.baseURL = null;
    this.headers = initialHeader;
    this.client = axios.create();

    // Interceptor
    this._setupInterceptors();
  }

  /**
   * Setup interceptors
   */
  _setupInterceptors() {
    // Interceptor - Request
    this.client.interceptors.request.use(
      this._handleRequest.bind(this),
      this._handleRequestError.bind(this)
    );

    // Interceptor - Response
    this.client.interceptors.response.use(
      this._handleResponse.bind(this),
      this._handleResponseError.bind(this)
    );
  }

  /**
   * Request interceptor
   *
   * @param {object} config - config
   * @returns {object} config
   */
  async _handleRequest(config) {
    config.headers = this.headers;
    config.baseURL = this.baseURL;

    // Wait for the same request to complete
    await requestPollHandler(config);

    // API Key Handler
    config = await apiKeyHandler(config);

    return config;
  }

  /**
   * Request error interceptor
   *
   * @param {object} error - error
   * @returns {object} error
   */
  async _handleRequestError(error) {
    return Promise.reject(error);
  }

  /**
   * Response interceptor
   *
   * @param {object} response - response
   * @returns {object} response
   */
  async _handleResponse(response) {
    const requestKey = response.config.meta?.requestKey;

    requestKey && removeRequestFromPool(requestKey);

    return response;
  }

  /**
   * Response error interceptor
   *
   * @param {object} error - error
   * @returns {object} error
   */
  async _handleResponseError(error) {
    const originalRequest = error.config;
    const status = error.response ? error.response.status : null;

    const requestKey = error.config?.meta?.requestKey;

    requestKey && removeRequestFromPool(requestKey);

    switch (status) {
      // 400: Bad Request
      // 400: Invalid PIN Code
      case 400:
        return {
          ...error.response.data.Message,
          isSuccess: false,
          status: 400,
        };

      // 401: Refresh Access Token
      // 401: When user pressed Play button while non-user in details, CDN return 401
      case 401:
        const auth = await getStorage(storageKeys.auth);

        if (!isEmpty(auth) && !originalRequest.retry) {
          originalRequest.retry = true;
          await refreshAccessToken(originalRequest);

          return this.client(originalRequest);
        } else {
          navigate(NavigationRoutes.signIn);

          return {
            ...error.response.data,
            isSuccess: false,
            status: 401,
          };
        }

      // 403: Getting API Key
      case 403:
        if (!originalRequest.retry) {
          originalRequest.retry = true;
          await getApiKey(originalRequest);

          return this.client(originalRequest);
        }

        break;

      // Other Response Cases
      default:
        return Promise.reject(error);
    }
  }

  /**
   * Set baseUrl
   *
   * @param {string} url - url
   */
  setBaseURL(url) {
    this.baseURL = url;
  }

  /**
   * Get baseUrl
   *
   * @returns {string} baseURL
   */
  getBaseURL() {
    return this.baseURL;
  }

  /**
   * Set mode
   *
   * @param {string} mode - url
   */
  setMode(mode) {
    this.mode = mode;
  }

  /**
   * Get mode
   *
   * @returns {string} mode
   */
  getMode() {
    return this.mode;
  }

  /**
   * Set header
   *
   * @param {string} key - key
   * @param {string} value - value
   */
  setHeader(key, value) {
    this.headers[key] = value;
  }

  /**
   * Get header
   *
   * @param {string} key - key
   * @returns {string} header key value
   */
  getHeader(key) {
    return this.headers[key];
  }

  /**
   * Get all header
   *
   * @returns {object} headers
   */
  getAllHeader() {
    return this.headers;
  }

  /**
   * Remove header
   *
   * @param {string} key - key
   */
  removeHeader(key) {
    delete this.headers[key];
  }

  /**
   * Set authorization
   *
   * @param {string} value - value
   */
  setAuth(value) {
    // TODO: should use removeHeader('Authorization); for remove data
    this.headers.Authorization = value ? `Bearer ${value}` : '';
  }

  /**
   * Set authorization
   *
   * @param {string} value - value
   */
  setDigi(value) {
    this.headers['accept-digi'] = value;
  }

  /**
   * Set accept language
   *
   * @param {string} value - value
   */
  setAcceptLanguage(value) {
    this.headers['accept-language'] = value;
  }

  // Actions

  /**
   * Menu request
   *
   * @returns {object} response
   */
  menuApis() {
    return this.client.get('/ui/menus');
  }

  /**
   * Dynamic get
   *
   * @param {string} url - value
   * @returns {object} response
   */
  dynamicGet(url) {
    return this.client.get(url);
  }

  /**
   * Dynamic post
   *
   * @param {string} url - value
   * @param {object} body - body
   * @param {object} headers - headers
   * @returns {object} response
   */
  dynamicPost(url, body = {}, headers = {}) {
    return this.client.post(url, body, headers);
  }

  /**
   * Dynamic put
   *
   * @param {string} url - value
   * @param {object} body - value
   * @param {object} headers - value
   * @returns {object} response
   */
  dynamicPut(url, body = {}, headers = {}) {
    return this.client.put(url, body, headers);
  }

  /**
   * Dynamic delete
   *
   * @param {string} url - value
   * @returns {object} response
   */
  dynamicDelete(url) {
    return this.client.delete(url);
  }
}

export default new Service();
