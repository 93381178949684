import TYPES from './types';

const actions = {
  init: () => ({
    type: TYPES.INIT,
  }),

  setDevice: (data) => ({
    type: TYPES.SET_DEVICE,
    payload: data,
  }),
};

export default actions;
