import Service from '../../middleware/services';
import { removeStorage, setStorage } from '../storage';
import STORAGE_KEYS from '../storageKeys';
import TYPES from './types';

const actions = {
  init: () => ({
    type: TYPES.INIT,
  }),

  setAuth: (authState) => {
    if (!authState) {
      Service.setAuth(null);
      removeStorage(STORAGE_KEYS.auth);

      return {
        type: TYPES.SET_AUTH,
        payload: {},
      };
    }

    Service.setAuth(authState.at);
    setStorage(STORAGE_KEYS.auth, authState);

    return {
      type: TYPES.SET_AUTH,
      payload: authState,
    };
  },
};

export default actions;
