import TYPES from './types';

/**
 * Menu Reducer
 *
 * @param {object} state - state
 * @param {object} action - action
 * @returns {object} - reducer object
 */
const reducer = (state, action) => {
  switch (action.type) {
    case TYPES.INIT:
      return {
        ...state,
        deviceState: {
          ...state.deviceState,
          isInitiated: true,
        },
      };

    case TYPES.SET_DEVICE:
      return {
        ...state,
        deviceState: {
          ...state.deviceState,
          isInitiated: true,
          data: {
            ...state.deviceState.data,
            ...action.payload,
          },
        },
      };
    default:
      throw new Error();
  }
};

export default reducer;
