import { Platform, NativeModules } from 'react-native';
import AsyncStorage from '@react-native-async-storage/async-storage';
import { isEmpty } from '../helpers/CommonHelper';

/**
 * Get Data from Storage
 *
 * @param {string} storageKey - storageKey
 * @returns {object} - storage data
 */
export const getStorage = async (storageKey) => {
  if (Platform.OS === 'ios') {
    try {
      const value = await NativeModules.UserDefaults.getValueForKey(
        storageKey,
        'TODAT'
      );

      try {
        return JSON.parse(value);
      } catch {
        return value;
      }
    } catch (e) {
      console.error(`${storageKey} parsing error: ${e}`);

      return {};
    }
  } else {
    try {
      const userStorage = await AsyncStorage.getItem(storageKey);

      return userStorage ? JSON.parse(userStorage) : {};
    } catch (e) {
      console.info(
        'Failed to get the data from storage with the key',
        storageKey
      );

      return {};
    }
  }
};

/**
 * Set Data to Storage
 *
 * @param {string} storageKey - storageKey
 * @param {object} data - data
 * @returns {object} - storage data
 */
export const setStorage = async (storageKey, data) => {
  if (Platform.OS === 'ios') {
    if (!isEmpty(data)) {
      try {
        const jsonValue =
          typeof data === 'string' || typeof data === 'number'
            ? data
            : JSON.stringify(data);

        await NativeModules.UserDefaults.setValueForKey(
          jsonValue,
          storageKey,
          'TODAT'
        );

        await NativeModules.UserDefaults.getValueForKey(storageKey, 'TODAT');
      } catch (e) {
        console.error('Set Storage error,' + e);
      }
    }
  } else {
    try {
      const userStorage = await AsyncStorage.setItem(
        storageKey,
        JSON.stringify(data)
      );

      return userStorage;
    } catch (e) {
      console.info(
        'Failed to set the data to storage with the key',
        storageKey
      );
    }
  }
};

/**
 * Remove Data From Storage
 * Removes data from the storage and returns the removed data
 *
 * @param {string} storageKey - storageKey
 * @returns {object} - removed storage data
 */
export const removeStorage = async (storageKey) => {
  if (Platform.OS === 'ios') {
    try {
      await NativeModules.UserDefaults.deleteValueForKey(storageKey, 'TODAT');
    } catch (e) {
      console.error('d,' + e);
    }
  } else {
    try {
      const userStorage = await AsyncStorage.removeItem(storageKey);

      return userStorage;
    } catch (e) {
      console.info(
        'Failed to remove and get the data from storage with the key',
        storageKey
      );
    }
  }
};
