import { createNavigationContainerRef } from '@react-navigation/native';
import { lastFocusKey, getCurrentFocusKey } from '../../helpers/FocusHelper';

/**
 * Navigation Referance
 */
const navigationRef = createNavigationContainerRef();

/**
 * Navigate for Service
 *
 * @param {string} name - navigate name
 * @param {object} params - params
 */
function navigate(name, params = {}) {
  /**
   * Current Page
   *
   * @returns {object} - last route
   */
  const currentPage = () => {
    return navigationRef.getState().routes[
      navigationRef.getState().routes?.length - 1
    ];
  };

  lastFocusKey.set({ key: currentPage().key, data: getCurrentFocusKey() });

  if (navigationRef.isReady()) {
    navigationRef.navigate(name, params);
  }
}

export { navigationRef, navigate };
