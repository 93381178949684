const requestPool = new Map();

/**
 * Set request from requestPool
 *
 * @param {object} config - config
 * @returns {string} - key
 */
const createRequestKey = (config) => {
  return JSON.stringify({
    url: config?.url,
    params: config?.params,
    data: config?.data,
  });
};

/**
 * Add request to requestPool
 *
 * @param {string} requestKey - requestKey
 * @returns {void}
 */
const addRequestToPool = (requestKey) => {
  requestPool.set(requestKey, true);
};

/**
 * Check request in requestPool
 *
 * @param {string} requestKey - requestKey
 * @returns {boolean} - isRequestInPool
 */
const isRequestInPool = (requestKey) => {
  return requestPool.has(requestKey);
};

/**
 * Remove request from requestPool
 *
 * @param {string} requestKey - requestKey
 * @returns {void}
 */
const removeRequestFromPool = (requestKey) => {
  requestPool.delete(requestKey);
};

/**
 * Request Poll Handler
 *
 * @param {object} config - config
 * @returns {void|Promise} - Promise
 */
const requestPollHandler = (config) => {
  const requestKey = createRequestKey(config);

  if (isRequestInPool(requestKey)) {
    return Promise.reject({
      message: 'Same request is already in progress',
      key: requestKey,
    });
  }

  config.meta = { requestKey };
  addRequestToPool(requestKey);
};

export {
  createRequestKey,
  isRequestInPool,
  addRequestToPool,
  removeRequestFromPool,
  requestPollHandler,
};
