export const HTTP_URL = 'http://';
export const HTTPS_URL = 'https://';

// Api Modes
export const apiModes = {
  test: 'test',
  regression: 'regression',
  live: 'live',
};

export const collectionRailList = [
  'MovieCollection',
  'MovieStarCollection',
  'SerieCollection',
  'CompetitionCollection',
];

export const certificateUrlForFairPlay =
  'https://digiturk.stage.ott.irdeto.com/licenseServer/streaming/v1/digiturk/getcertificate?applicationId=app1';

export const videwineLicenceURL = `https://digiturk.stage.ott.irdeto.com/licenseServer/widevine/v1/digiturk/license?contentId=`;
export const playreadyLicenceURL = `https://digiturk.stage.ott.irdeto.com/licenseServer/playready/v1/digiturk/license?contentId=`;
export const fairplayLicenceURL = `digiturk.stage.ott.irdeto.com/licenseServer/streaming/v1/digiturk/getckc?contentId=`;
// export const fairplayLicenceURL = `https://digiturk.test.ott.irdeto.com/licenseServer/streaming/v1/digiturk/getckc?contentId=${irdetoContentId}&keyId=${keyId}&ls_session=${irdetoSessionTokenExpireDate}`;

const STREAM_FORMATS = {
  undefined: 0,
  DASH: 1,
  SS: 2,
  HLS: 3,
  MP4: 4,
};

export { STREAM_FORMATS };
