import React, { createContext, useReducer, useEffect, useContext } from 'react';
import { getStorage } from '../storage';
import storageKeys from '../storageKeys';

import actions from './actions';
import reducer from './reducer';

const Auth = createContext();

/**
 * Context Hook
 *
 * @returns {module:JSX.Element} - JSX.Element
 */
export const useAuth = () => useContext(Auth);

const initialState = {
  authState: {
    isInitiated: false,
    auth: false,
  },
};

/**
 * Auth Provider component.
 *
 * @param {object} props - props
 * @param {object} props.children - children
 * @returns {module:JSX.Element} The Auth context provider component.
 */
export const AuthProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  useEffect(() => {
    (async () => {
      // auth check on storage
      const auth = await getStorage(storageKeys.auth);

      if (auth && Object.keys(auth).length) {
        dispatch(actions.setAuth(auth));
      }

      // init
      dispatch(actions.init());
    })();
  }, []);

  const data = {
    ...state,
    authDispatch: dispatch,
  };

  return <Auth.Provider value={data}>{children}</Auth.Provider>;
};
