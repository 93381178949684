import React, { createContext, useContext, useReducer, useMemo } from 'react';

import reducer from './reducer';

const initialState = {
  deviceState: {
    isInitiated: false,
    error: null,
    data: null,
  },
};

const Device = createContext();

/**
 * Context Hook
 *
 * @returns {module:JSX.Element} - JSX.Element
 */
export const useDevice = () => useContext(Device);

/**
 * Provider
 *
 * @param {object} props - props
 * @param {object} props.children - children
 * @returns {module:JSX.Element} - JSX.Element
 */
export const DeviceProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  const data = useMemo(
    () => ({
      deviceState: state.deviceState,
      deviceDispatch: dispatch,
    }),
    [state.deviceState]
  );

  return <Device.Provider value={data}>{children}</Device.Provider>;
};
